<i18n locale="th" lang="yaml" src="@i18n/service/payment.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
payment.detail : "รายละเอียดค่าใช้จ่าย"
payment.detail.description : "ค่าอะไหล่และค่าบริการต่าง ๆ จะคิดเฉพาะที่อยู่ในใบประเมินราคาหรือใบสรุปค่าใช้จ่ายงานซ่อมเท่านั้น"
payment.status : "สถานะการชำระเงิน"

service_payment.field.total_fee.error : "ยอดที่ต้องชำระผิดพลาด"
service_payment.field.completed_date.display : "ชำระครบเมื่อวันที่ {date}"

service_payment.field.part_fee.help : "ค่าอะไหล่ทั้งหมด"
service_payment.field.service_fee.help : "ค่าบริการทั้งหมดเช่น ค่าเปิดฝา หรือ ค่าบริการนอกสถานที่ระยะไกล"
service_payment.field.technician_fee.help: "ค่าแรงช่างในการเปลี่ยนอะไหล่ทั้งหมด"
service_payment.field.extra_service_fee.help: "กรุณาบันทึกเหตุผลของค่าบริการเพิ่มเติมในช่อง หมายเหตุ-ค่าใช้จ่าย"
service_payment.field.approved_claimed_fee.help: "ค่าอะไหล่และค่าแรงช่างที่เคลมผ่าน"
service_payment.field.discount_fee.help : "ส่วนลดสำหรับลูกค้า, กรุณาบันทึกเหตุผลของค่าบริการเพิ่มเติมในช่อง หมายเหตุ-ค่าใช้จ่าย"
service_payment.field.remark.placeholder : "เช่น ส่วนลดพิเศษได้จากการชำระเงินสด หรือ ใส่รายละเอียดการชำระ เป็นต้น"
service_payment.field.payment_remark.placeholder : "หมายเหตุเพิ่มเติมการชำระเงิน เช่น ลูกค้าโอนเงินมาแล้ว 50% ที่เหลือจะโอนมาภายในวันที่ xxx เป็นต้น"

payment.action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
payment.action.ask_leave.message : "คุณได้แก้ไขข้อมูลการชำระเงิน และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"

payment.action.ask_reset : "ยกเลิกการแก้ไข"
payment.action.ask_reset.title : "@:payment.action.ask_reset"
payment.action.ask_reset.message : "คุณต้องการยกเลิกการแก้ไข และ นำข้อมูลเดิมในระบบกลับมา ?"

payment.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
payment.action.update.confirm.title : "ยืนยันการแก้ไขค่า"
payment.action.update.confirm.message : "คุณต้องการบันทึกการแก้ไขข้อมูลการชำระเงินของใบซ่อมนี้ ?"
payment.action.update.confirm.success : "ทำการบันทึกข้อมูลการชำระเงินของงานซ่อม {name} เรียบร้อย"
</i18n>
<!--
Mode to display
	- Ignore canUpdateProp
	- Check self canUpdatePayment (status != cancel, can update payment by role)
	- canUpdateDetail - canUpdatePayment && payment status != complete
	- canUpdatePaymentStatus -

-->
<template>
	<div v-show="!loading" class="page-padding">
		<template v-if="(canUpdatePayment || canUpdatePaymentDetail) && !isPaymentCompleted">
			<div class="page-action-right">
				<a-button @click="handleReset">
					{{$t('payment.action.ask_reset')}}
				</a-button>
				<a-button type="primary" class="btn-submit" @click="() => handleSave()">
					{{$t('common.save')}}
				</a-button>
			</div>
			<ErrorMessagePane :error="saveError" />
		</template>

		<div class="mytab-section-title">
			{{$t('payment.detail')}}
			<div class="mytab-section-description">
			{{$t('payment.detail.description')}}
			</div>
		</div>
		<div class="payment-subsection payment-detail">
			<div class="payment-row">
				<label>{{$t('service_payment.field.part_fee')}} :</label>
				<div v-if="isPaymentCompleted" class="payment-value">
					{{paymentValue.part_fee | price}}
				</div>
				<div v-else class="payment-value">
					{{partsFeeDisplay | price}}
				</div>
				<div class="help">
					{{$t('service_payment.field.part_fee.help')}}
				</div>
			</div>
			<div class="payment-row">
				<label>{{$t('service_payment.field.technician_fee')}} :</label>
				<div v-if="isPaymentCompleted" class="payment-value">
					{{paymentValue.technician_fee | price}}
				</div>
				<div v-else class="payment-value">
					{{technicianFeeDisplay | price}}
				</div>
				<div class="help">
					{{$t('service_payment.field.technician_fee.help')}}
				</div>
			</div>
			<div class="payment-row">
				<label>{{$t('service_payment.field.service_fee')}} :</label>
				<div v-if="isPaymentCompleted" class="payment-value">
					{{paymentValue.service_fee | price}}
				</div>
				<div v-else class="payment-value">
					{{serviceFeeDisplay | price}}
				</div>
				<div class="help">
					{{$t('service_payment.field.service_fee.help')}}
				</div>
			</div>
			<div class="payment-row">
				<label>{{$t('service_payment.field.extra_service_fee')}} :</label>
				<div v-if="isPaymentCompleted" class="payment-value">
					{{paymentValue.extra_service_fee | price}}
				</div>
				<div v-else class="payment-input">
					<a-input-number
						v-model="paymentValue.extra_service_fee"
						:min="0"
						:step="0.01" :precision="2"
						class="myinput-number"/>
					<span class="ant-form-text">{{$t('common.currency.unit')}}</span>
				</div>
				<div class="help">
					{{$t('service_payment.field.extra_service_fee.help')}}
				</div>
			</div>
			<div class="payment-row summary-1">
				<label>{{$t('service_payment.field.total_cost')}} :</label>
				<div class="payment-value">
					{{totalFeeExcludeDiscount | price}}
				</div>
			</div>

			<div class="payment-row">
				<label>{{$t('service_payment.field.approved_claimed_fee')}} :</label>
				<div class="payment-value">
					{{approvedClaimedFeeDisplay | price}}
				</div>
				<div class="help">
					{{$t('service_payment.field.approved_claimed_fee.help')}}
				</div>
			</div>
			<div class="payment-row">
				<label>{{$t('service_payment.field.discount_fee')}} :</label>
				<div v-if="isPaymentCompleted" class="payment-value">
					{{paymentValue.discount_fee | price}}
				</div>
				<div v-else class="payment-input">
					<a-input-number
						v-model="paymentValue.discount_fee"
						:min="0"
						:step="0.01" :precision="2"
						class="myinput-number"/>
					<span class="ant-form-text">{{$t('common.currency.unit')}}</span>
				</div>
				<div class="help">
					{{$t('service_payment.field.discount_fee.help')}}
				</div>
			</div>
			<div class="payment-row summary-2">
				<label>{{$t('service_payment.field.total_fee')}} :</label>
				<div class="payment-value">
					{{totalFee|price}}
				</div>
			</div>
			<div class="payment-row full">
				<label>{{$t('service_payment.field.remark')}} :</label>
				<a-textarea
					v-model="paymentValue.remark"
					style="max-width:800px;"
					:auto-size="{ minRows: 4,maxRows: 10 }"
					:read-only="!canUpdatePaymentDetail"
					:placeholder="$t('service_payment.field.remark.placeholder')"/>
			</div>
		</div>
		<hr class="payment-divider" />
		<div class="mytab-section-title">
			{{$t('payment.status')}}
		</div>
		<div class="payment-subsection payment-detail">
			<template v-if="canUpdatePaymentDetail">
				<div class="payment-row">
					<label class="valign-top">{{$t('service_payment.field.status')}} : </label>
					<div v-if="isPaymentCompleted" class="payment-value">
						{{$tenum('service.payment.status', payment.status)}}
					</div>
					<div v-else class="payment-input payment-status-input">
						<a-radio-group v-model="paymentValue.status" :options="paymentStatusOptions" @change="handlePaymentStatus" />
					</div>
				</div>
				<div class="payment-row">
					<label>{{$t('service_payment.field.completed_date')}}* : </label>
					<div v-if="isPaymentCompleted" class="payment-value">
						{{$dayjs(paymentValue.completed_date).format("LL")}}
					</div>
					<div v-else class="payment-input">
						<MyDatePicker
							v-model="paymentValue.completed_date"
							:read-only="paymentValue.status != 'complete'"
							format="DD MMMM YYYY"/>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="payment-row">
					<label class="valign-top">{{$t('service_payment.field.status')}} : </label>
					<div class="payment-value">
						{{$tenum('service.payment.status',paymentValue.status)}}
					</div>
					<div v-if="$notEmpty(paymentValue.completed_date)" class="help">
						{{$t('service_payment.field.completed_date.display',{ date: $dayjs(paymentValue.completed_date).format("LL") })}}
					</div>
				</div>
			</template>
			<div class="payment-row full">
				<label>{{$t('service_payment.field.payment_remark')}} :</label>
				<a-textarea
					v-model="paymentValue.payment_remark"
					style="max-width:800px;"
					:auto-size="{ minRows: 4,maxRows: 10 }"
					:read-only="!canUpdatePaymentDetail"
					:placeholder="$t('service_payment.field.payment_remark.placeholder')"/>
			</div>
		</div>


		<div v-if="(canUpdatePayment || canUpdatePaymentDetail) && !isPaymentCompleted" class="page-action-right">
			<a-button @click="handleReset">
				{{$t('payment.action.ask_reset')}}
			</a-button>
			<a-button type="primary" class="btn-submit" @click="() => handleSave()">
				{{$t('common.save')}}
			</a-button>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import DronePartMixin from "@mixins/drone/DronePartMixin.vue"
import {InputNumber,Radio} from "ant-design-vue"
import {copyDeep} from "@utils/objectUtil"
import {toNumber} from "@utils/stringUtil"
import FormError from "@utils/errors/FormError"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import {scrollTopContent,getEnumSelectOptions} from "@utils/formUtil"
import axios from "axios"
import _isEqual from "lodash/isEqual"
import { mapGetters, mapState } from 'vuex'
import {CLAIM_STATUS,PAYMENT_STATUS,getPartsByTypeService, calculateTechnicianFeeByTotal} from "@utils/serviceUtil"

function _defaultPayment() {
	return {
		part_fee : 0 ,
		service_fee : 0 ,
		extra_service_fee : 0,
		technician_fee : 0,
		discount_fee : 0,
		approved_claimed_fee : 0,
		total_fee_exclude_discount : 0,
		total_fee : 0,
		remark : undefined,
		status : undefined,
		completed_date : undefined,
		payment_remark : undefined,
	}
}

export default {
	components : {
		"a-radio-group" : Radio.Group,
		"a-input-number" : InputNumber,
		ErrorMessagePane, MyDatePicker,
	} ,
	mixins : [PageMixin, ServiceObjectMixin, DronePartMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false,
			payment : {} ,
			claimRequest : {} ,

			paymentValue : _defaultPayment() ,
			ignorePreventExit : false,
			oldFormData : {} ,
			saveError : undefined,

			partsFeeDisplay: null,
			technicianFeeDisplay: null,
			serviceFeeDisplay: null,

		}
	} ,
	computed : {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapState('drone', ['manHourPrice']),
		...mapGetters('drone',['getPartsInfo']) ,
		approvedClaimedFeeDisplay(){
			if(this.isPaymentCompleted) return this.claimRequest.updated_datetime ? this.paymentValue.approved_claimed_fee : 0

			return this.claimRequest.status === CLAIM_STATUS.STATUS_APPROVED && this.claimRequest.total_claim_fee ? this.claimRequest.total_claim_fee : 0
		},
		canUpdatePaymentDetail() {
			// Can update even service status is complete
			return this.canViewPayment &&
				this.$authorize('update','service',{companyId : this.service.company_id}) &&
				(this.service.status != 'close' || this.payment.status != 'complete')
		} ,
		totalFeeExcludeDiscount() {
			return this.isPaymentCompleted ? this.paymentValue.part_fee + this.paymentValue.service_fee + this.paymentValue.technician_fee + this.paymentValue.extra_service_fee :
					this.partsFeeDisplay + this.serviceFeeDisplay + this.technicianFeeDisplay + parseFloat(this.paymentValue.extra_service_fee)
		} ,
		totalFee() {
			return this.isPaymentCompleted ? this.totalFeeExcludeDiscount - (this.paymentValue.discount_fee + this.approvedClaimedFeeDisplay) :
					this.totalFeeExcludeDiscount - (this.paymentValue.discount_fee + this.approvedClaimedFeeDisplay)
		} ,
		isPaymentCompleted() {
			return this.payment.status === PAYMENT_STATUS.STATUS_COMPLETE
		} ,
		paymentStatusOptions() {
			const options = ['new','in_progress','complete']
			return getEnumSelectOptions(this,'service.payment.status',options)
		} ,
		showPaymentStatusWarningMessage() {
			return this.canUpdatePaymentDetail && this.payment.status != 'complete' && this.paymentValue == 'complete'
		}

	} ,
	watch : {
		$route(newVal) {
			if (!this.payment || newVal.params.id != this.payment.id) {
				this.fetchData()
			}
		} ,
	} ,
	mounted() {
		this.fetchData();
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		calculateFee(partsInfo = []) {
			return partsInfo.reduce((acc, i) => acc + i.price, 0)
		},
		calculateTechnicianFee(partsInfo) {
			const sumTotalTime = partsInfo.reduce((acc, i) => acc + i.totalTime, 0)
			return calculateTechnicianFeeByTotal(sumTotalTime, this.manHourPrice)
		},
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			if (!this.canViewPayment) {
				return this.$open({name : 'service/view',params: {id : serviceId}})
			}

			this.loading = true
			axios.get("/api/services/"+serviceId+"/payment")
				.then((response) => {
					this.payment = response.data.data.payment
					this.serviceParts = response.data.data.service_parts
					this.initPayment()
					return axios.get("/api/services/"+serviceId+"/jobs")
				})
				.then((response) => {
					const serviceParts = response.data.data.service_parts.repair
					const partsInfo = this.getPartsInfo(serviceParts)
					const repairPartsInfo = getPartsByTypeService(partsInfo, false)
					const servicePartsInfo = getPartsByTypeService(partsInfo, true)
					this.partsFeeDisplay = this.calculateFee(repairPartsInfo)
					this.serviceFeeDisplay =  this.calculateFee(servicePartsInfo)
					this.technicianFeeDisplay =  this.calculateTechnicianFee(repairPartsInfo)

					return axios.get("/api/services/"+serviceId+"/claim-request")
				})
				.then((response) => {
					this.claimRequest = response.data.data.claim_request
				})
				.catch((error) => {
					this.fetchError(error)
				})
				.finally(() => {
					this.loading = false
				})
		} ,
		initPayment() {
			this.saveError = undefined
			this.paymentValue = _defaultPayment()
			if (this.payment) {
				this.paymentValue.part_fee = toNumber(this.payment.part_fee)
				this.paymentValue.service_fee = toNumber(this.payment.service_fee)
				this.paymentValue.extra_service_fee = toNumber(this.payment.extra_service_fee)
				this.paymentValue.technician_fee = toNumber(this.payment.technician_fee)
				this.paymentValue.discount_fee = toNumber(this.payment.discount_fee)
				this.paymentValue.approved_claimed_fee = toNumber(this.payment.approved_claimed_fee)
				this.paymentValue.total_fee_exclude_discount = toNumber(this.payment.total_fee_exclude_discount)
				this.paymentValue.total_fee = toNumber(this.payment.total_fee)
				this.paymentValue.remark = this.$notEmpty(this.payment.remark) ? this.payment.remark : undefined
				this.paymentValue.status = this.$notEmpty(this.payment.status) ? this.payment.status : 'new'
				this.paymentValue.completed_date = this.payment.completed_date
				this.paymentValue.payment_remark = this.payment.payment_remark
			}


			this.$nextTick(() => {
				this.oldFormData = this.dumpFormData()
			})
		} ,

		handlePaymentStatus() {
			if (this.paymentValue.status != 'complete') {
				this.paymentValue.completed_date = undefined
			}
		} ,
		dumpFormData() {
			const paymentForm = copyDeep(this.paymentValue)
			const formData = {}

			if (paymentForm.status != 'complete') {
				paymentForm.completed_date = null
			}
			formData.payment = { id : this.payment.id, ...paymentForm}
			return formData
		} ,
		handleReset() {
			if (!this.canUpdatePayment)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				scrollTopContent()
				return
			}
			this.$confirm({
				title : this.$t('payment.action.ask_reset.title') ,
				content : this.$t('payment.action.ask_reset.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.initPayment()
					scrollTopContent()
				} ,
			})
		} ,
		validateFormData(formData) {
			this.saveError = undefined
			const formError = new FormError(this)
			if (this.canUpdatePaymentDetail || this.canUpdatePayment) {
				if (this.totalFee < 0) {
					formError.addGeneralMessage(this.$t('service_payment.field.total_fee.error'))
				}
			}
			if (formData.payment.status == 'complete' && !this.$notEmpty(formData.payment.completed_date)) {
				formError.addGeneralMessage(this.$t("validate.required",{field : this.$t('service_payment.field.completed_date')}))
			}

			if (formError.hasErrors()) {
				this.saveError = formError
				this.$nextTick(()=> {
					scrollTopContent()
				})
				return false
			} else {
				return true
			}
		} ,
		snapDisplayedPaymentData() {
			return {
				part_fee: this.partsFeeDisplay,
				service_fee: this.serviceFeeDisplay,
				technician_fee: this.technicianFeeDisplay,
				total_fee: this.totalFee,
				approved_claimed_fee: this.approvedClaimedFeeDisplay,
				total_fee_exclude_discount: this.totalFeeExcludeDiscount,
			}
		},
		handleSave() {
			if (!(this.canUpdatePaymentDetail || this.canUpdatePayment))
				return
			const formData = this.dumpFormData()
			formData.payment = {...formData.payment, ...this.snapDisplayedPaymentData()}

			if (!this.isDataChange(formData)) {
				this.$message.info(this.$t('payment.action.update.no_change'))
				return
			}
			if (!this.validateFormData(formData)) {
				return
			}
			this.$confirm({
				title : this.$t('payment.action.update.confirm.title') ,
				content : this.$t('payment.action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)

					axios.put("/api/services/"+this.service.id+"/payment",formData).then((response)=>{
						this.ignorePreventExit = true
						this.$message.success(this.$t('payment.action.update.confirm.success',{name:this.service.service_no}))
						this.$router.go()
					}).catch((error) => {
						this.saveError = error
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (!this.canUpdatePayment || this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		}
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('payment.action.ask_leave.title') ,
				content : this.$t('payment.action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.payment-subsection {
	margin-left : 64px;
	margin-bottom : 32px;
	.mobile & {
		margin-left : 16px;
	}
}
.payment-subsection-full {
	margin-bottom : 32px;
}
.payment-row {
	margin-bottom : 12px;
	> .title {
		color : @secondary-color;
		font-family: @font-family-title;
		font-size : 1.1em;
		margin-bottom : 8px;
		text-decoration: underline;
	}
	label {
		color : @info-color;
		.mobile & {
			margin-bottom : 4px;
		}
	}
	.help {
		color : @text-muted;
		margin-top : 2px;
		font-size : 0.95em;
	}
}
.payment-detail {
	.payment-row {
		margin-bottom : 16px;
		label {
			display: inline-block;
			width : 130px;
			text-align : right;
			margin-right : 4px;
		}
		.payment-input {
			display : inline-block;
			.mobile &.payment-status-input::v-deep .ant-radio-wrapper {
				display : block;
			}
		}
		.payment-value {
			display : inline-block;
			color : @primary-color;
		}
		.help {
			margin-left: 124px;
		}
		&.full > label {
			width : 100%;
			text-align : left;
			display : block;
			margin-bottom : 4px;
		}

	}
}
.payment-divider {
	margin-bottom : 24px;
}
.summary-1 {
	font-size : 1.1em;
	font-weight: 600;
}
.summary-2.payment-row {
	font-size : 1.1em;
	font-weight: 600;
	background-color : @blue-5;
	padding : 8px;
	max-width: 600px;
	margin-left : -16px;
	border-radius : @border-radius-base;
	label , .payment-value {
		color : @white;
	}
}
</style>
